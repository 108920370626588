import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shippable-product-form"
export default class extends Controller {

  static targets = ["shippableProductStatus", "warehouseControlName"];


  // 倉庫取扱い名称末尾にステータスを記載する
  addStatusDescription() {
    this.warehouseControlNameTarget.value += `${this.statusDescription()}`;
  }

  statusDescription() {
    switch(this.shippableProductStatusTarget.value) {
      case "sample":
        return "(サンプル採取品)";
      case "stained":
        return "(ステインド)";
      case "cover_torn":
        return "(カバートーン)";
      case "decaf":
        return "(デカフェ)";
      case "none_name":
        return "(N/W)";
      case "over_weight":
        return "(オーバーウェイト)";
      case "under_weight":
        return "(アンダーウェイト)";
      case "collected_for_plant_quarantine":
        return "(植検採取品)";
      default:
        return ""
    }
  }
}
