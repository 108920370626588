import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="convert-to-half-width"
export default class extends Controller {
  connect() {
    this.element.addEventListener("input", this.validateInput.bind(this));
  }

  validateInput(event) {
    const input = event.target.value;
    const isFullWidth = /[Ａ-Ｚａ-ｚ０-９]/.test(input);
    if (isFullWidth) {
      event.target.value = input.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s){return String.fromCharCode(s.charCodeAt(0)-0xFEE0);});
    }
  }
}
