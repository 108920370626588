import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shipping-send-form"
export default class extends Controller {

  static targets = [
    'deliveryDate', // 納品希望日
    'nextDate',
    'afterNextDate',
    'isEarliestDeliveryDate'
  ]

  // ボタンが押されると納品希望日がセットされる
  setDeliveryDate(event) {
    // 最短日を選択していれば発火しない
    if (this.isEarliestDeliveryDateTarget.checked) { return }
    // 納品希望日欄のvalueを更新
    const element = event.target
    const dateValue = element.dataset.date.replace(/\"/g,"")
    this.deliveryDateTarget.value = dateValue;
    // 翌日
    let nextDate = new Date(element.dataset.date);
    nextDate.setDate(nextDate.getDate() + 1);
    // 翌々日
    let afterNextDate = new Date(element.dataset.date);
    afterNextDate.setDate(afterNextDate.getDate() + 2);
    // ボタンのテキスト, data更新
    this.nextDateTarget.textContent = `翌日(${(nextDate.getMonth()+1).toString().padStart(2, "0")}/${nextDate.getDate().toString().padStart(2, "0")}${this.dayName(nextDate)})`;
    this.nextDateTarget.dataset.date = `${nextDate.getFullYear()}-${(nextDate.getMonth()+1).toString().padStart(2, "0")}-${nextDate.getDate().toString().padStart(2, "0")}`;
    this.afterNextDateTarget.textContent = `翌々日(${(afterNextDate.getMonth()+1).toString().padStart(2, "0")}/${afterNextDate.getDate().toString().padStart(2, "0")}${this.dayName(afterNextDate)})`;
    this.afterNextDateTarget.dataset.date = `${afterNextDate.getFullYear()}-${(afterNextDate.getMonth()+1).toString().padStart(2, "0")}-${afterNextDate.getDate().toString().padStart(2, "0")}`;    
  }

  // 納品希望日を最短日とすると入力不可にする
  disableDeliveryDate(){
    if (this.isEarliestDeliveryDateTarget.checked) {
      // 最短日をチェックすると納品希望日を無効にする
      this.deliveryDateTarget.value = undefined;
      this.deliveryDateTarget.setAttribute("disabled", "");
    } else {
      // 最短日のチェックを外すと納品希望日を有効にする
      this.deliveryDateTarget.removeAttribute("disabled");
    };
  }

  // 曜日
  dayName(date) {
    const dayNames = ['(日)', '(月)', '(火)', '(水)', '(木)', '(金)', '(土)'];
    let dayName =  dayNames[date.getDay()];
    return dayName
  }
}
