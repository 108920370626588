import {
  Controller
} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["simpleSearch", "startContractDate", "endContractDate", "search", "collapse"];
  static values = { isCollapseShow: Boolean };

  onclickSimpleSearch() {
    if (this.simpleSearchTarget.classList.contains('btn-light')) {
      this.simpleSearchTarget.classList.replace('btn-light', 'btn-primary');
      this.searchTarget.classList.replace('btn-primary', 'btn-light');
    }
  }

  // 契約日期間の初めが終わりよりも未来に入力されたら終わりも同じ値をセットする
  setEndContractDate(event) {
    if (event.target.value > this.endContractDateTarget.value){
      this.endContractDateTarget.value = event.target.value
    }
  }

  // 契約期間の終わりが初めよりも過去に入力されたら初めの同じ値をセットする
  setStartContractDate(event) {
    if (event.target.value < this.startContractDateTarget.value){
      this.startContractDateTarget.value = event.target.value
    }
  }

  onclickSearch() {
    if (this.isCollapseShowValue) {
      this.simpleSearchTarget.classList.replace('btn-light', 'btn-primary');
      this.searchTarget.classList.replace('btn-primary', 'btn-light');

    } else {
      this.simpleSearchTarget.classList.replace('btn-primary', 'btn-light');
      this.searchTarget.classList.replace('btn-light', 'btn-primary');

      const simple_query = document.getElementById('js-simple-search-form').value

      // 詳細検索展開時に、シンプル検索のクエリを自動でセットする
      if(simple_query){
        // Uからはじまるときは品目コード、それ以外の時は当面、品名にセットする
        if(simple_query.slice(0, 1) == "U"){
          document.getElementsByClassName('js-search-product_code')[0].value = simple_query;
        }else{
          document.getElementsByClassName('js-search-sale_name')[0].value = simple_query;
        }
      }
    }
    this.isCollapseShowValue = !this.isCollapseShowValue
  }
}