import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dselect"
export default class extends Controller {
  static values = { size: String }

  connect() {
    dselect(this.element, {
      search: true, // Toggle search feature. Default: false
      creatable: false, // Creatable selection. Default: false
      clearable: true, // Clearable selection. Default: false
      maxHeight: '450px', // Max height for showing scrollbar. Default: 360px
      size: !!this.sizeValue ? this.sizeValue : '', // Can be "sm" or "lg". Default ''
    });
  }
}
