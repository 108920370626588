import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="simpeMode"
// あらかじめclassで指定した要素をシンプルモードで表示したり詳細表示する
export default class extends Controller {

  show_simple(){

    document.querySelector('.js-simple-mode-button').classList.add("d-none");
    document.querySelector('.js-detail-mode-button').classList.remove("d-none");

    document.querySelectorAll('.js-only-detail-mode').forEach(function(element) {
      element.classList.add("d-none");
    });
  }

  show_detail(){
    document.querySelector('.js-simple-mode-button').classList.remove("d-none");
    document.querySelector('.js-detail-mode-button').classList.add("d-none");

    document.querySelectorAll('.js-only-detail-mode').forEach(function(element) {
      element.classList.remove("d-none");
    });
  }
}
