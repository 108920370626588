import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shipping-result-form"
export default class extends Controller {

  static targets = [
    'shipDate', // 出庫日のinput
    'deliveryDate' // 納品日のinput
  ];

  // 選択された出庫日を納品日選択のmin属性に設定する
  shipDateOnChange() {
    this.deliveryDateTarget.removeAttribute("disabled");
    this.deliveryDateTarget.setAttribute("min", this.shipDateTarget.value);
  }
}
