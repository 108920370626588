import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bcart-customer-form"
// bcart会員の保管料パターン設定画面を制御する
export default class extends Controller {

  static targets = ['storageFeeSelect', 'storageFee'];
  
  connect() {
    console.log("con")

    if (this.storageFeeSelectTarget.value == 'monthly') {
      this.storageFeeTarget.removeAttribute("disabled");
    } else {
      this.storageFeeTarget.setAttribute("disabled", "");
    }
  }

  onSelected(event){
    if (event.target.value == 'monthly') {
      this.storageFeeTarget.removeAttribute("disabled");
    } else {
      this.storageFeeTarget.setAttribute("disabled", "");
    }
  }
}
