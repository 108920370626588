import * as bootstrap from "bootstrap";
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tooltip"
export default class extends Controller {

  static values = { title: String, direction: String };

  connect() {
    const elem = this.element
    elem.setAttribute("data-bs-toggle", "tooltip");
    elem.setAttribute("title", this.titleValue);
    if (this.directionValue) {elem.setAttribute("data-bs-placement", this.directionValue)};
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    });
  }
}
