import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-button"
export default class extends Controller {

  static values = { key: String, value: String };

  connect() {
    let url = new URL(location.href);
    if (this.is_clicked(url)) {
      // クリックされていたらボタンのスタイルを変え、リクエストパラメータをリセットする
      this.element.classList.replace('btn-outline-secondary', 'btn-secondary');
      url.searchParams.delete(this.keyValue);
      this.element.href = url.href
    } else {
      // クリックされていなかったらリクエストパラメータに値を追加する
      url.searchParams.set(this.keyValue, this.valueValue)
      this.element.href = url.href
    }
  }

  // 条件がクリックされたかどうかリクエストパラメータと照らしあわせて判別
  is_clicked(url) {
    return url.searchParams.get(this.keyValue) == this.valueValue
  }
}
