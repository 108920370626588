import { Controller } from "@hotwired/stimulus"

// カンマ区切りのemailsをvalidateする
// Connects to data-controller="emails_validator"
export default class extends Controller {

  static targets = [ "emails" , "alertArea"]

  check(){
    this.emailsTarget.value = this.emailsTarget.value.replace(/\s+/g, "")

    if(this._validateCommaSeparatedEmails(this.emailsTarget.value)){
      console.log("valid")
      this.alertAreaTarget.classList.add("d-none")
    }else{
      console.log("invalid!")
      this.alertAreaTarget.classList.remove("d-none")
    }
  }

  _isValidEmail(email) {
    // 単一のメールアドレスをチェックする正規表現 EmailsValidator とそろえている
    const regex = /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/;
    return regex.test(email);
  }
  
  _validateCommaSeparatedEmails(str) {
    const emails = str.split(',');
  
    // every() メソッドは、配列内のすべての要素が指定した関数で真を返すかどうかをチェックします
    return emails.every(email => this._isValidEmail(email.trim()));
  }
}
