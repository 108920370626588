// app/javascript/controllers/highlight_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["clickable"]

  highlight(event) {
    // 以前にハイライトされた要素の背景色をクリア
    this.clickableTargets.forEach((element) => {
      element.style.backgroundColor = "";
    });

    // クリックされた要素の背景色を変更
    event.currentTarget.style.backgroundColor = "#d0e7ff"; // 薄い青色
  }
}