// select_checkbox
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select_checkbox"
export default class extends Controller {
  static targets = ["allSelectButton"]

  all_select() {
    this.allSelectButtonTarget.form.querySelectorAll("input").forEach(element => {
      if(element.type == "checkbox"){
        element.checked = true
      }
    });
  }

  all_unselect() {
    this.allSelectButtonTarget.form.querySelectorAll("input").forEach(element => {
      if(element.type == "checkbox"){
        element.checked = false
      }
    });
  }  
}
