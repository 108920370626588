import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="meihen-send-form"
export default class extends Controller {

  static targets = ['completedDate', 'startCountingDate', 'specialNotes', 'previewLink', "previewLinkInForm"];
  static values = { completeddate: String, startcountingdate: String };

  connect() {
    if (this.completeddateValue) { 
      this.setPreviewLinkHref()
    } else {
      this.setPreviewLinkInFormHref()
    };
  }

  onSelect() {
    this.setPreviewLinkInFormHref();
  }

  // プレビューのリンクにフォームの値をリクエストパラメータとして追加する
  setPreviewLinkHref() {
    let url = new URL(this.previewLinkTarget.href);
    url.searchParams.set("completed_date", this.completeddateValue);
    url.searchParams.set("start_counting_date", this.startcountingdateValue);
    url.searchParams.set("special_notes", []);
    this.previewLinkTarget.setAttribute('href', url);
  }

  // プレビューのリンクにフォームの値をリクエストパラメータとして追加する
  setPreviewLinkInFormHref() {
    let url = new URL(this.previewLinkInFormTarget.href);
    url.searchParams.set("completed_date", `${this.completedDateTarget.value}`);
    url.searchParams.set("start_counting_date", `${this.startCountingDateTarget.value}`);
    url.searchParams.set("special_notes", `${this.specialNotesTarget.value}`)
    this.previewLinkInFormTarget.setAttribute('href', url);
  }
}
