import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sale-contract-form"
export default class extends Controller {

  static targets = ['storageFeeMonthly', 'storageFeeCustomFeeAfterSixMonth', 'storageFeeEndDate'];

  onChecked(event){
    console.log(this.targets)
    if (event.target.value == 'monthly') {
      this.storageFeeMonthlyTarget.removeAttribute("disabled");
      this.storageFeeEndDateTarget.removeAttribute("disabled");
      this.storageFeeCustomFeeAfterSixMonthTarget.setAttribute("disabled", "");
    } else if (event.target.value == 'custom_fee_after_six_month') {
      this.storageFeeCustomFeeAfterSixMonthTarget.removeAttribute("disabled");
      this.storageFeeEndDateTarget.removeAttribute("disabled");
      this.storageFeeMonthlyTarget.setAttribute("disabled", "");
    } else if (event.target.value == 'none') { // 保管料なし
      this.storageFeeEndDateTarget.setAttribute("disabled", ""); // 保管料無料期限欄を無効化
    } else {
      this.storageFeeMonthlyTarget.setAttribute("disabled", "");
      this.storageFeeEndDateTarget.removeAttribute("disabled");
      this.storageFeeCustomFeeAfterSixMonthTarget.setAttribute("disabled", "");
    }
  }
}
