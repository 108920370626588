import {
  Controller
} from "@hotwired/stimulus";

export default class extends Controller {
  static values = { id: String };

  inputOnNormalSalableProduct() {
    this.removeBcartRegistrateBtn(); // Bcart登録ボタンを消す
    this.removeNameCopyField(); // 品名のワンクリックコピーエリアの削除
  }

  inputOnOptionalSalableProduct() {
    this.setRequiredOnContract(); // 契約番号にrequired属性のセット
    this.removeBcartRegistrateBtn(); // Bcart登録ボタンを消す
    this.removeCodeCopyField(); // 商品コードのワンクリックコピーエリアの削除
    this.removeNameCopyField(); // 品名のワンクリックコピーエリアの削除
    this.disableSaleContractBtn(); // 売り契約作成ボタンの無効化
  }

  // 入り数か価格が入力されていたら契約番号入力必須にする
  setRequiredOnContract() {
    const quantityPerUnitField = document.getElementById(`${this.idValue}_quantity_per_unit`);
    const priceField = document.getElementById(`${this.idValue}_price`);
    const contractField = document.getElementById(`${this.idValue}_contract`);
    if (!quantityPerUnitField.value && !priceField.value) {
      contractField.removeAttribute("required");
    } else {
      contractField.setAttribute("required", "");
    }
  }

  // Bcart登録ボタンを消す
  removeBcartRegistrateBtn() {
    const bcartRegistrateBtn =  document.getElementById(`bcart_product_set_registrate-${this.idValue}`);
    if (bcartRegistrateBtn) bcartRegistrateBtn.remove();
  }

  // 商品コードのワンクリックコピーエリアの削除
  removeCodeCopyField() {
    const codeCopyField = document.getElementById(`${this.idValue}_code`);
    if (codeCopyField) codeCopyField.remove();
  }

  // 品名のワンクリックコピーエリアの削除
  removeNameCopyField() {
    const nameCopyField = document.getElementById(`${this.idValue}_name`);
    if (nameCopyField) nameCopyField.remove();
  }

  // 売り契約作成ボタンの無効化
  disableSaleContractBtn() {
    const saleContractBtn = document.getElementById(`${this.idValue}_sale_contract_btn`);
    console.log(saleContractBtn);
    console.log("disabled sale_contract");
    if (saleContractBtn) {
      saleContractBtn.setAttribute("aria-disabled", true); // リンクの無効化
      saleContractBtn.classList.add("disabled") // ボタンを非アクティブに見せる
    }
  }

  // 小分け選択時に配送グループを確定する
  setSubdividedShippingGroup(){
    const select = document.getElementById(`${this.idValue}_shipping_group_select`);
    const subdivide_option = select.querySelectorAll(`#${this.idValue}_shipping_group_select .js_subdivide_shipping_group`)[0]

    if(document.getElementById(`${this.idValue}_is_subdivided`).checked){
      select.querySelectorAll(`.js_gentai_shipping_group`).forEach((element) => {
        element.classList.add('d-none');
      });
      subdivide_option.classList.remove("d-none");

      // 小分け用配送グループをセットする
      subdivide_option.selected = true;
    }else{
      select.querySelectorAll(`.js_gentai_shipping_group`).forEach((element) => {
        element.classList.remove('d-none');
      });
      subdivide_option.classList.add("d-none");
      subdivide_option.selected = false;
    }
  }

  // 現物直送選択時に配送グループを確定する
  setActualProductDirect(){
    const select = document.getElementById(`${this.idValue}_shipping_group_select`);
    const actual_product_option = select.querySelectorAll(`#${this.idValue}_shipping_group_select .js_actual_product_shipping_group`)[0]

    if(document.getElementById(`${this.idValue}_is_actual_product_direct`).checked){
      select.querySelectorAll(`.js_gentai_shipping_group`).forEach((element) => {
        element.classList.add('d-none');
      });
      actual_product_option.classList.remove("d-none");

      // 小分け用配送グループをセットする
      actual_product_option.selected = true;
    }else{
      select.querySelectorAll(`.js_gentai_shipping_group`).forEach((element) => {
        element.classList.remove('d-none');
      });
      actual_product_option.classList.add("d-none");
      actual_product_option.selected = false;
    }
  }
}