import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bcart-customer-search"
export default class extends Controller {

  static values = { locationbyobjectid: String };

  oninput(event) {
    // valueには（１）テキストフィールドへの入力時の文字列、
    // （２）datalistのオプション選択時の顧客ID、
    // （３）その後わかりやすく表示するための顧客名の3通りが入る
    const value = event.target.value

    if(this.isOnlyDigits(value)){
      this.updateHiddenFieldValue(value)
    }

    this.updateTextFieldValue(event, value)
    this.fetchResponse(value, this.locationbyobjectidValue)
  }

  // フォームから送信する値を書き換える
  updateHiddenFieldValue(value) {
    const hiddenBcartCustomerIdField = document.getElementById(`hidden_bcart_customer_id${this.locationbyobjectidValue}`);
    hiddenBcartCustomerIdField.value = value
  }

  // datalist選択時、通常はoptionのvalueがフォームにセットされるのを、選択肢名に差し替える
  updateTextFieldValue(event, value) {
    const datalist = document.getElementById(`bcart_customer_option_list_object_id_${this.locationbyobjectidValue}`);
    const selectedOption = Array.from(datalist.options).find(option => option.value === value);
    // 選択されたoptionがあれば、inputの値を設定
    if (selectedOption) {
      event.target.value = selectedOption.innerHTML.trim();
    }
  }

  // bcart_customer_searches#indexへturbo_streamリクエストを送る
  fetchResponse(query, locationbyobjectid) {
    const url = new URL("/bcart_customer_search", window.location.origin);
    url.searchParams.append("query", query);
    url.searchParams.append("location_by_object_id", this.locationbyobjectidValue);
    fetch(url.toString(), {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(response => response.text())
    .then(message => Turbo.renderStreamMessage(message))
  }

  isOnlyDigits(str) {
    return /^\d+$/.test(str);
  }
}
