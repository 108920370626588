import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sale-contract"
export default class extends Controller {
  connect() {
    console.log("connected!!");
  }

  onchange(event) {
      this.enableInputs(event.target.id);
  }

  enableInputs(dom_id) {
    const fields = document.getElementsByClassName("contract-form")
    Array.prototype.forEach.call(fields, (field => {
      if (field.id === `${dom_id}_quantity` || field.id === `${dom_id}_unit_price`) {
        field.removeAttribute("disabled");
      } else {
        field.setAttribute("disabled", "");
      }
    })
    )
  }
}
