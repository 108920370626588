import {
  Controller
} from "@hotwired/stimulus";

// 自由規格を追加する+ボタンの色を変える
export default class extends Controller {

  toggleBtnStyle() {
    const elem = this.element
    if (elem.classList.contains('bi-plus-circle')) {
      elem.classList.replace('bi-plus-circle', 'bi-plus-circle-fill');
    } else {
      elem.classList.replace('bi-plus-circle-fill', 'bi-plus-circle');
    }
  }
}