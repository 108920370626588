import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cancel-salable-products-button"
export default class extends Controller {
  static values = { domid: String };
  static targets = ['cancelButton'];

  cancelForm() {
    const form = document.getElementById(`${this.domidValue}_edit_salable_product_form`);
    form.remove();
  }
}
