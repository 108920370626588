import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="normal-salable-product"
export default class extends Controller {
  static targets = [ "unit", "weight", "category", "style_of_packing", "quantity_per_unit", "prefix", "suffix", "stock_parent_id", "case_quantity", "output", "is_roasted" ]

  connect() {
    this.update_unit_kg()
    if(!this.outputTarget.value){
      this.updateQuantityPerUnit()
    }
  }

  // 初回のみ読まれる
  update_unit_kg(){
    if(this.unitTarget.value == "g"){
      console.log(this.weightTarget.value * 1000)
      this.weightTarget.value = this.weightTarget.value * 1000;
    }
  }

  updateQuantityPerUnit(){

    const quantity_per_unit = this.quantity_per_unitTarget.value

    if(this.hasStock_parent_idTarget){
      if(quantity_per_unit == 1){
        this.stock_parent_idTarget.selectedIndex = 0;
        this.stock_parent_idTarget.disabled = true;
        this.case_quantityTarget.disabled = true;
      }else {
        this.stock_parent_idTarget.disabled = false;
        this.case_quantityTarget.disabled = false;
      }
    }

    this.updateOutput()
  }

  updateOutput() {

    const is_roasted = this.is_roastedTarget.value

    console.log("is_roasted " + is_roasted)
    if(is_roasted === "false"){
      return
    }

    const unit = this.unitTarget.value
    const weight = Math.floor(this.weightTarget.value)
    const prefix = this.prefixTarget.value
    const suffix = this.suffixTarget.value

    const style_of_packing = this.style_of_packingTarget.value
    const quantity_per_unit = this.quantity_per_unitTarget.value

    const case_quantity = this.case_quantityTarget.value

    const category = this.categoryTarget.options[this.categoryTarget.selectedIndex].text
    let product_name = `${prefix}${weight}${unit}`

    if(category != "その他"){
      product_name += `(${category})`
    }

    if(quantity_per_unit > 1){
      product_name += ` ${quantity_per_unit}${style_of_packing}`
    }
    if(case_quantity > 1){
      product_name += ` ${case_quantity}ケース`
    }

    product_name += suffix

    this.outputTarget.value = product_name
  }
}