import {
  Controller
} from "@hotwired/stimulus";

export default class extends Controller {

  static values = { name: String };
  static targets = ['copySelectField', 'copyField'];

  copy(){
    console.log('name copy')
    console.log(this.nameValue)
    navigator.clipboard.writeText(this.nameValue);
    this.setAnimation();
  }

  // コピー対象がセレクトフォーム内にある場合
  copySelectTarget(){
    navigator.clipboard.writeText(this.copySelectFieldTarget.options[this.copySelectFieldTarget.selectedIndex].textContent);
    this.setAnimation();
  }

  // フォームの入力値をコピーする
  copyField(){
    navigator.clipboard.writeText(this.copyFieldTarget.value);
    this.setAnimation();
  }

  setAnimation(){
    this.element.classList.add('icon_vertical');

    // 1秒後に class 除外
    const sleep = new Promise(resolve => setTimeout(resolve, 400));
    sleep.then(() =>
      this.element.classList.remove('icon_vertical')
    );
  }
}