// productCopy
import {
  Controller
} from "@hotwired/stimulus";

// 支払い予測シートに貼るためのデータ
// 購入先	品番	商品名	数量	容量(kg)	単価(JPY)	合計金額(JPY)	契約開始日（入港予定）

export default class extends Controller {

  static values = {
    supplier: String,
    code: String,
    saleName: String,
    contractQuantity: Number,
    purchasePriceTaxExcluded: Number,
    total: Number,
    arrivalInPortSchedule: String
  };

  copy(){
    console.log(this.nameValue)

    navigator.clipboard.writeText(`${this.supplierValue}\t${this.saleNameValue}\t${this.codeValue}\t${this.contractQuantityValue}\t${this.purchasePriceTaxExcludedValue}\t${this.totalValue}\t${this.arrivalInPortScheduleValue}`);

    this.setAnimation();
  }

  setAnimation(){
    this.element.classList.add('icon_vertical');

    // 1秒後に class 除外
    const sleep = new Promise(resolve => setTimeout(resolve, 400));
    sleep.then(() =>
      this.element.classList.remove('icon_vertical')
    );
  }
}