// 日付が祝日・日曜かどうかチェックする
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dateCheck"

// ここから取得
// 祝日判定APIも世の中にはあるが・・・
// https://www8.cao.go.jp/chosei/shukujitsu/syukujitsu.csv
// 毎年更新できるとよさそう。
const holidays = [
'2023-01-01',
'2023-01-02',
'2023-01-09',
'2023-02-11',
'2023-02-23',
'2023-03-21',
'2023-04-29',
'2023-05-03',
'2023-05-04',
'2023-05-05',
'2023-07-17',
'2023-08-11',
'2023-09-18',
'2023-09-23',
'2023-10-09',
'2023-11-03',
'2023-11-23',
'2024-01-01',
'2024-01-08',
'2024-02-11',
'2024-02-12',
'2024-02-23',
'2024-03-20',
'2024-04-29',
'2024-05-03',
'2024-05-04',
'2024-05-05',
'2024-05-06',
'2024-07-15',
'2024-08-11',
'2024-08-12',
'2024-09-16',
'2024-09-22',
'2024-09-23',
'2024-10-14',
'2024-11-03',
'2024-11-04',
'2024-11-23'
]
export default class extends Controller {

  static targets = ['date', 'alertArea']

  connect() {
    this.check()
  }

  // fetch で野良APIをつかおうと思ったが、うまく取得できず挫折・・・。挫折の記録を残しておきます。
  // async check(){
  //   console.log("start check");
  //   const input_date = new Date(this.dateTarget.value)

  //   const year = input_date.getFullYear().toString().padStart(4, '0');
  //   const month = (input_date.getMonth() + 1).toString().padStart(2, '0');
  //   const day = input_date.getDate().toString().padStart(2, '0');


  //   // https://s-proj.com/utils/holiday.html
  //   // 野良APIでやや心配だけれど・・・
  //   const holiday_api = "https://s-proj.com/utils/checkHoliday.php?kind=ph&date="

  //   const response = await fetch(holiday_api + year + month + day, {mode: 'no-cors'});
  //   console.log("text:" + await response.text())
  // }

  check(){
    const input_date = new Date(this.dateTarget.value)
    // 日曜・祝日のとき
    // type="date" のvalueは「2022-01-01」の形式で取得できることを想定）
    if(input_date.getDay() == 0 || holidays.includes(this.dateTarget.value)){
      this.dateTarget.classList.add("text-danger")
      this.alertAreaTarget.classList.remove("d-none")
    }else{
      this.dateTarget.classList.remove("text-danger")
      this.alertAreaTarget.classList.add("d-none")
    }
  }
}