import {
  Controller
} from "@hotwired/stimulus";
import {
  Modal
} from "bootstrap";
export default class extends Controller {
  static targets = ["modal"];
  connect() {
    // console.log("start connect!!");

    // モーダルが閉じられたら要素を削除
    this.element.addEventListener('hidden.bs.modal', () => {
      this.element.remove();
    })

    document.addEventListener('turbo:submit-end', (event) => {
      console.log(event.detail)
      if (event.target.dataset.keepmodalopen === 'true') {
        // submit完了後もモーダルを開いたままにするオプション
        console.log('modal keep opening')
      } else if (event.detail.success) {
        this.hide_modal();
      } else {
        console.log('modal error occured')
      }
    });
    this.modal.show();
  }

  // 閉じるボタンを押すと発火
  hide_modal() {
    // console.log('hide modal')
    this.modal.hide();
  }

  hideBeforeRender(event) {
    event.preventDefault()
    this.element.addEventListener('hidden.bs.modal', event.detail.resume);
    this.hide_modal();
  }

  get modal() {
    if (this._modalInstance == undefined) {
      this._modalInstance = new Modal(this.modalTarget);
      // console.log('modal instance generated')
    }
    return this._modalInstance;
  }
}