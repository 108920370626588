import {
  Controller
} from "@hotwired/stimulus";

import {
  Toast
} from "bootstrap"

export default class extends Controller {
  static targets = ["toast"];
  connect() {
    this.toast.show();
  }

  get toast() {
    if (this._toastInstance == undefined) {
      this._toastInstance = new Toast(this.toastTarget);
    }
    return this._toastInstance;
  }
}