import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-form"
export default class extends Controller {

  static targets = ['selectSupplier', 'selectWarehouse'];

  // 仕入先が選択されるとそれに紐づく出荷倉庫を自動で選択
  setWarehouseBySupplierSelectedValue() {
    if (this.selectWarehouseTarget.value) { return } // 選択済みならreturn
    // 選択された仕入先の名前
    const selectedInnerHtml = this.selectSupplierTarget.options[this.selectSupplierTarget.selectedIndex].innerHTML;
    let warehouseOptions = this.selectWarehouseTarget.options
    for (var i = 0; i < warehouseOptions.length; i++) {
      if (warehouseOptions[i].text === selectedInnerHtml) {
        warehouseOptions[i].selected = true;
        break;
      };
    };
  }
}
