import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sale-contract-document-form"
export default class extends Controller {

  static targets = [ "notes",  "notes_template" ]

  connect() {
    if(this.notesTarget.value === ""){
      console.log("set")
      this.setNotes()
    }
  }

  setNotes(){
    const notes_template = this.notes_templateTarget.value
    this.notesTarget.value = notes_template
  }
}
