import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-reset"
export default class extends Controller {
  static targets = ["resetButton"]

  reset_text_form() {
    this.resetButtonTarget.form.querySelectorAll("input").forEach(element => {
      if(element.type == "text" || element.type == "date"){
        element.value = ""
        element.defaultValue = ""
      }
    });

    this.resetButtonTarget.form.querySelectorAll("select").forEach(element => {
      element.selectedIndex = -1
      element.querySelectorAll("option").forEach(option => {
        option.defaultSelected = false
      })
    });
  }
}
