import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sample-request-detail-form"
export default class extends Controller {

  static targets = [
    'supplier',
    'warehouse',
    'deliveryDate', // 納品希望日
    'isEarliestDeliveryDate'
  ]
connect() {
  this.disableDeliveryDate();
}
  // 依頼先は仕入先か倉庫のどちらか一方しか選択できないように制御する
  onSelectReceiver(event) {
    if (!event.target.value) {return} // 初期化でも発火するので値がなければreturn
    if (event.target.dataset.model === "supplier" && !!this.warehouseTarget.value) {
      this.deleteReceiverValue("warehouse");
    } else if (event.target.dataset.model === "warehouse" && !!this.supplierTarget.value) {
      this.deleteReceiverValue("supplier");
    }
  }

  // 選択されていない方の依頼先の値を削除する
  deleteReceiverValue(model) {
    console.log(`${model}---delete!`)
    if (model == "supplier") {
      document.getElementsByClassName("dselect-clear")[0].click();
    } else if (model === "warehouse") {
      document.getElementsByClassName("dselect-clear")[1].click();
    }
  }

  // 納品希望日を最短日とすると入力不可にする
  disableDeliveryDate() {
    if (this.isEarliestDeliveryDateTarget.checked) {
      // 最短日をチェックすると納品希望日を無効にする
      this.deliveryDateTarget.value = undefined;
      this.deliveryDateTarget.setAttribute("disabled", "");
    } else {
      // 最短日のチェックを外すと納品希望日を有効にする
      this.deliveryDateTarget.removeAttribute("disabled");
    };
  }
}
